<template>
  <div class="container">
    <video
      class="video"
      src="@/assets/video/1.mp4"
      autoplay
      loop
      controls
      controlslist="nodownload noremoteplayback"
    ></video>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.video {
  width: 100%;
}
</style>
